@font-face {
    font-family: 'NettoOT';
    src: url('assets/fonts/Netto/NettoOT.woff')  format("woff"),
    url('assets/fonts/Netto/NettoOT.otf')  format("opentype"),
    url('assets/fonts/Netto/NettoOT.ttf')  format('truetype');
}

@font-face {
    font-family: 'NettoOT Bold';
    src: url('assets/fonts/Netto/NettoOT-Bold.woff')  format("woff"),
    url('assets/fonts/Netto/NettoOT-Bold.otf')  format("opentype"),
    url('assets/fonts/Netto/NettoOT-Bold.ttf')  format('truetype');
}

@font-face {
    font-family: 'Calibri';
    src: url('assets/fonts/Calibri/Calibri.woff')  format("woff"),
    url('assets/fonts/Calibri/Calibri.otf')  format("opentype"),
    url('assets/fonts/Calibri/Calibri.ttf')  format('truetype');
}

body {
    margin: 0;
    padding: 0;
    font-family: 'NettoOT';
}

a#atlwdg-trigger:after{
    content: "Report Issue";
    font-size: 12px;
}

#atlwdg-trigger {
    position: fixed;
    width: fit-content;
    left: unset !important;
    right: 0%;
    z-index: 2;
    font-size: 0;
}
